<template>
  <div class="app-graphs d-flex align-items-end">
    <div
      v-for="{ id, height, background } in graphs"
      :key="id"
      class="app-graphs--col"
      :style="{
        background: background,
        height: `${height}%`
      }"
    />
  </div>
</template>

<script>
import { getId } from "@/utills/getId";

export default {
  name: "AppGraphs",
  props: {
    baseValue: { type: Number, required: true },
    values: { type: Array, required: true },
    colors: { type: Array, default: () => ["#2F80ED", "#E0E0E0"] }
  },

  computed: {
    graphs() {
      return this.values.map((val, idx) => {
        return {
          id: getId(),
          height: (val * 100) / this.baseValue,
          background: this.colors[idx] ?? this.colors[1]
        };
      });
    }
  }
};
</script>

<style lang="scss">
.app-graphs {
  height: 75px;
  padding-left: 4px;
  padding-right: 4px;
  border-bottom: 1px solid #bdbdbd;
  max-width: max-content;
}
.app-graphs--col {
  width: 15px;
  border-top: {
    left-radius: 20px;
    right-radius: 20px;
  }
}
</style>
