import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppPaymentsTable = _resolveComponent("AppPaymentsTable")!
  const _component_CreateRefundModal = _resolveComponent("CreateRefundModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AppPaymentsTable, {
      "pagination-capture-key": 'Payments',
      "table-body": _ctx.newData,
      "total-rows": _ctx.paymentsTotal,
      "current-page": _ctx.viewConfig.page,
      "per-page": _ctx.viewConfig.perPage,
      "initial-view-configuration": _ctx.viewConfig,
      onChangeViewConfiguration: _ctx.changeViewConfigurationHandler,
      onChangeFilter: _ctx.changeFilterHandler,
      onRefundCreate: _ctx.openRefundModal
    }, null, 8, ["table-body", "total-rows", "current-page", "per-page", "initial-view-configuration", "onChangeViewConfiguration", "onChangeFilter", "onRefundCreate"]),
    _createVNode(_component_CreateRefundModal, {
      title: 'Create Refund',
      data: _ctx.refundData,
      loading: _ctx.isLoading,
      opened: _ctx.refundDialog,
      photo: _ctx.userImage,
      onHide: _ctx.clearData,
      onCancel: _ctx.clearData,
      onConfirm: _ctx.onRefundConfirm
    }, null, 8, ["data", "loading", "opened", "photo", "onHide", "onCancel", "onConfirm"])
  ], 64))
}