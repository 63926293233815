<template>
  <div class="d-flex error" :class="`error-type-${type}`">
    <SvgIcon icon="warn" class="me-2" />
    <p class="error-message" v-html="message" />
  </div>
</template>

<script>
import SvgIcon from "@/components/ui/SvgIcon";
export default {
  name: "TextErrorMessage",
  components: { SvgIcon },
  props: {
    message: { type: String, default: "Something wrong" },
    type: {
      type: String,
      default: "default",
      validator: t => ["bg", "default"].indexOf(t) !== -1
    }
  }
};
</script>

<style scoped lang="scss">
.error {
  color: $color-danger;
  .icon {
    margin-top: 0.2em;
    margin-bottom: 0;
    font-size: 1.1em;
  }

  &-type-bg {
    background-color: rgba($color-danger, 0.15);
    padding: 1.1em 1.3em;
    font-size: 10px;
    line-height: 1.3;
    border-radius: $border-radius;
    .icon {
      display: none;
    }
  }
}
</style>
